.carousel {
    width: 100%;
}

.carousel-inner>.item>img, .carousel-inner>.item>a>img {
    display: block;
    height: auto;
    max-width: 100%;
    line-height: 1;
    width: 100%;
    }

    .carousel-inner .carousel-item {
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        position: absolute;
        transition: opacity 1s ease-in-out;
      }
      .carousel-inner .carousel-item:first-of-type {
        position: relative;
      }
      .carousel-inner .active {
        opacity: 1;
      }
