.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}